import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebComponent } from './web.component';

const title = "NaharaSoft - Soluciones Tecnológicas a la Medida";
const webRoutes: Routes = [
    {
        path: '',
        component: WebComponent,
        children: [
            {
                path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), data: { title: title }
            },
            {
                path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), data: { title: title }
            },
            {
                path: 'services', loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesModule), data: { title: 'Servicios | ' + title }
            },
            {
                path: 'about-us', loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule), data: { title: 'Nosotros | ' + title }
            },
            {
                path: 'work-with-us', loadChildren: () => import('./pages/work-with-us/work-with-us.module').then(m => m.WorkWithUsModule), data: { title: 'Trabaja con Nosotros | ' + title }
            },
            {
                path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { title: 'Productos | ' + title }
            },
            {
                path: 'contact', loadChildren: () => import('./pages/page-contact/page-contact.module').then(m => m.PageContactModule), data: { title: 'Contacto | ' + title }
            },
            {
                path: 'software-development', loadChildren: () => import('./pages/software-development/software-development.module').then(m => m.SoftwareDevelopmentModule), data: { title: 'Desarrollo de Software | ' + title }
            },
            {
                path: 'web-development', loadChildren: () => import('./pages/web-development/web-development.module').then(m => m.WebDevelopmentModule), data: { title: 'Desarrollo Web | ' + title }
            },
            {
                path: 'mobile-development', loadChildren: () => import('./pages/mobile-development/mobile-development.module').then(m => m.MobileDevelopmentModule), data: { title: 'Desarrollo Móvil | ' + title }
            },
            {
                path: 'web-design', loadChildren: () => import('./pages/web-design/web-design.module').then(m => m.WebDesignModule), data: { title: 'Diseño Web | ' + title }
            },
            {
                path: 'consultancy-ti', loadChildren: () => import('./pages/consultancy-ti/consultancy-ti.module').then(m => m.ConsultancyTiModule), data: { title: 'Consultoria TI | ' + title }
            },
            {
                path: 'ecommerce-project', loadChildren: () => import('./pages/ecommerce-project/ecommerce-project.module').then(m => m.EcommerceProjectModule), data: { title: 'Proyectos Ecommerce | ' + title }
            },
            {
                path: 'support', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule), data: { title: 'Soporte | ' + title }
            },
            {
                path: 'site-map', loadChildren: () => import('./pages/site-map/site-map.module').then(m => m.SiteMapModule), data: { title: 'Mapa de Sitio | ' + title }
            },
            {
                path: 'personal-data-processing-policies', loadChildren: () => import('./pages/personal-data-processing-policies/personal-data-processing-policies.module').then(m => m.PersonalDataProcessingPoliciesModule), data: { title: 'Políticas de Tratamiento de Datos Personales | ' + title }
            },
            {
                path: 'rapidnahara', loadChildren: () => import('./pages/rapidnahara/rapidnahara.module').then(m => m.RapidnaharaModule), data: { title: 'RapidNahara | ' + title }
            },
            {
                path: 'naharasys-erp', loadChildren: () => import('./pages/naharasys-erp/naharasys-erp.module').then(m => m.NaharasysErpModule), data: { title: 'NaharaSys ERP | ' + title }
            },
            {
                path: 'naharasoft-academic', loadChildren: () => import('./pages/naharasoft-academic/naharasoft-academic.module').then(m => m.NaharaSoftAcademicModule), data: { title: 'NaharaSoft Academic | ' + title }
            },
            {
                path: 'development-under-scrum', loadChildren: () => import('./pages/development-under-scrum/development-under-scrum.module').then(m => m.DevelopmentUnderScrumModule), data: { title: 'Desarrollo Bajo Scrum | ' + title }
            },
            {
                path: '', redirectTo: 'home', pathMatch: 'full'
            },
            {
                path: '**', redirectTo: 'panel', pathMatch: 'full'
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(webRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class WebRoutingModule { }