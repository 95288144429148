<header [ngClass]="['main-header', 'container-fluid']">
    <nav [ngClass]="['container', 'navbar', 'navbar-expand-lg']">
        <div [ngClass]="['mr-auto']">
            <a [routerLink]="['/home']" alt="NaharaSoft" title="NaharaSoft"><img src="assets/images/logo_naharasoft_sas.png"
                    [ngClass]="['main-logo']"></a>
        </div>
        <button [ngClass]="['navbar-toggler', 'navbar-icon-color']" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <i [ngClass]="['fas', 'fa-align-justify']"></i>
        </button>
        <div [ngClass]="['collapse', 'navbar-collapse']" id="navbarSupportedContent">
            <div [ngClass]="['mr-auto']">
            </div>
            <ul [ngClass]="['navbar-nav']">
                <li [ngClass]="['item-menuHeader']"><a [routerLinkActive]="[ 'main-active-link'] "
                        [routerLink]="[ '/home'] " href="# " [ngClass]="['main-menu-link']">{{ 'headerMenuHome' | translate}}</a></li>
                <li [ngClass]="['item-menuHeader']"><a [routerLinkActive]="[ 'main-active-link'] "
                        [routerLink]="[ '/about-us'] " [ngClass]="['main-menu-link']">{{ 'headerMenuAboutUs' | translate}}</a></li>
                <li [ngClass]="['item-menuHeader']"><a [routerLinkActive]="[ 'main-active-link'] "
                        [routerLink]="[ '/products'] " href="# " [ngClass]="['main-menu-link']">{{ 'headerMenuProducts' | translate}}</a></li>
                <li [ngClass]="['item-menuHeader']"><a [routerLinkActive]="[ 'main-active-link'] "
                        [routerLink]="[ '/services'] " href="# " [ngClass]="['main-menu-link']">{{ 'headerMenuServices' | translate}}</a></li>
                <li [ngClass]="['item-menuHeader']"><a [routerLinkActive]="[ 'main-active-link'] "
                            [routerLink]="[ '/work-with-us'] " href="# " [ngClass]="['main-menu-link']">{{ 'headerMenuWorkWithUs' | translate}}</a></li>
                <li [ngClass]="['item-menuHeader']"><a [routerLinkActive]="[ 'main-active-link'] "
                        [routerLink]="[ '/contact'] " [ngClass]="['main-menu-link']">{{ 'headerMenuContactUs' | translate}}</a></li>
            </ul>
        </div>
    </nav>
</header>