import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerPageService {
  public _isLoading = new BehaviorSubject(true);
  isLoading$ = this._isLoading.asObservable();
  
  constructor() { }

  showSpinner(): void{
    this._isLoading.next(true);
  }

  hideSpinner(): void{
    this._isLoading.next(false);
  }

}
