import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerPageComponent } from './spinner-page.component';



@NgModule({
  declarations: [
    SpinnerPageComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SpinnerPageComponent
  ]
})
export class SpinnerPageModule { }
