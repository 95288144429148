import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { SpinnerPageService } from "../../services/spinner-page/spinner-page.service";

@Injectable()
export class SpinnerPageInterceptor implements HttpInterceptor{
    constructor(private spinnerPageService: SpinnerPageService) { }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerPageService.showSpinner();
        return next.handle(req).pipe(finalize(() => setTimeout(() => {
            this.spinnerPageService.hideSpinner()
        }, 2000)));
    }
}