<div [ngClass]="['overlay-spinner']" *ngIf="toggleSpinner">
    <div>
        <div [ngClass]="['wrapper-logo']">
            <img src="assets/images/logo_naharasoft_sas.png" [ngClass]="['main-logo']">
        </div>
        <div [ngClass]="['wrapper-spinner']">
            <div [ngClass]="['lds-ring']"><div></div><div></div><div></div><div></div></div>
        </div>        
    </div>    
</div>

