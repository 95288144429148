import { Component, HostListener, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

//For default english
const defaultLanguage = "en";
//and Add spanish
const additionalLanguages = ["es", "it"];
const languages: string[] = [defaultLanguage].concat(additionalLanguages);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'naharasoftsas';
  isShow: boolean = false;
  topPosToStartShowing = 100;
  urlWhatsApp: string = "";

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService
  ) {

    
  }

  ngOnInit() {
     //Asignamos el lenguaje predefinido y los disponibles
     this.translate.setDefaultLang(defaultLanguage);
     this.translate.addLangs(additionalLanguages);
     //Nos basamos en el lenguaje del navegador para cambiar el lenguaje
     let initLang = this.translate.getBrowserLang();
     if (languages.indexOf(initLang + "") === -1) {
        initLang = defaultLanguage;
     }
     //En base a lo anterior asignamos el lenguaje a usar
     this.translate.use(initLang + "");

     this.translate.stream('titlePageContac').subscribe((res:string) => {
     });


    /* Titulo Dinamico para el Aplicativo */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).pipe(
      map(() => this.activatedRouter)
    ).pipe(
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
    ).pipe(
      filter((route) => route.outlet === 'primary')
    ).pipe(
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      let routeCurrent = window.location.href;
      let arrayRouteMain = routeCurrent.split("#") || [];
      if(arrayRouteMain.length > 1){
        let arrayRouteChildrens = (arrayRouteMain[1].split("/")).filter(Boolean);
        let keyTranslateTitle = "";

        if(arrayRouteChildrens.length == 0){
          keyTranslateTitle = "home";          
        }else if(arrayRouteChildrens.length > 0){
          keyTranslateTitle = arrayRouteChildrens[0];          
        }        
        this.translate.stream('title-page-' + keyTranslateTitle).subscribe((res:string) => {
          this.titleService.setTitle(res);
        });
      }
      //this.titleService.setTitle(event['title'])
    });
  }

  @HostListener('window:scroll')
  checkScroll() {
      
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
