import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerPageService } from 'src/app/core/services/spinner-page/spinner-page.service';

@Component({
  selector: 'app-spinner-page',
  templateUrl: './spinner-page.component.html',
  styleUrls: ['./spinner-page.component.scss']
})
export class SpinnerPageComponent implements OnInit {

  subscriptionIsLoading: Subscription = new Subscription;
  toggleSpinner:boolean = true;

  constructor(private spinnerPageService: SpinnerPageService) { }

  ngOnInit(): void {    
    this.subscriptionIsLoading = this.spinnerPageService.isLoading$.subscribe(event => {
      this.toggleSpinner = event;
    });
  }
}
