<footer [ngClass]="['main-footer', 'container-fluid']">
    <div [ngClass]="['container']">
        <div [ngClass]="['row', 'mainContentFooter']">
            <div [ngClass]="['footerMain', 'col-12', 'col-sm-6', 'col-md-4', 'col-lg-3']">
                <h5 [ngClass]="['footerMainTitle']">{{ 'footerTitleContactUsBusiness' | translate }}</h5>
                <div [ngClass]="['footerContent', 'footerContactMenu']">
                    <ul [ngClass]="['footerMainMenu']">
                        <li><img src="assets/images/icons/home.svg" title="{{ 'footerNameBussiness' | translate }}" alt="{{ 'footerNameBussiness' | translate }}" /> {{ 'footerNameBussiness' | translate}}
                        </li>
                        <li><img src="assets/images/icons/envelope.svg" title="{{ 'footerAltTitleEmail' | translate }}"
                                alt="{{ 'footerAltTitleEmail' | translate }}" /> {{ 'footerEmailBusiness' | translate}}</li>
                        <li><img src="assets/images/icons/skype-logo.svg" title="Skype" alt="Skype" /> naharasoft</li>
                        <li><img src="assets/images/icons/whatsapp.svg" title="Whatsapp" alt="Whatsapp" /> (+57)
                            312-6574203</li>
                        <li><img src="assets/images/icons/phone.svg" title="{{ 'footerAltTitleCellPhone' | translate }}"
                                alt="{{ 'footerAltTitleCellPhone' | translate }}" /> (+57) 312-6574203</li>
                        <li><img src="assets/images/icons/phone.svg" title="{{ 'footerAltTitleTelephone' | translate }}"
                            alt="{{ 'footerAltTitleTelephone' | translate }}" /> (+57) 607-5483598</li>
                        <li><img src="assets/images/icons/position.svg" title="{{ 'footerAltTitleAddres' | translate }}" alt="{{ 'footerAltTitleAddres' | translate }}" /> C.C.
                            Boulevar Europa Cll 13 Av 6 Ofic 124</li>
                        <li><img src="assets/images/icons/location.svg" title="{{ 'footerAltTitleCity' | translate }}" alt="{{ 'footerAltTitleCity' | translate }}" /> Cúcuta - Colombia
                        </li>
                    </ul>
                </div>
            </div>
            <div [ngClass]="['footerMain', 'col-12', 'col-sm-6', 'col-md-4', 'col-lg-3']">
                <h5 [ngClass]="['footerMainTitle']">{{ 'footerTitleProductsBusiness' | translate}}</h5>
                <div [ngClass]="['footerContent']">
                    <ul [ngClass]="['footerMainMenu']">
                        <li>
                            <a href="https://naharasys.com/" target="_blank"><img src="assets/images/icons/checked.svg"
                                    title="Check" alt="Check" /> {{ 'footerTitleItemProductNaharaSysErp' | translate }}</a>
                        </li>
                        <!--
                        <li>
                            <a href="https://rapidnahara.com/" target="_blank"><img
                                    src="assets/images/icons/checked.svg" title="Check" alt="Check" /> {{ 'footerTitleItemProductNaharaExpress' | translate }}</a>
                        </li>                       
                        <li>
                            <a href="https://naharasoftacademic.com/" target="_blank"><img
                                    src="assets/images/icons/checked.svg" title="Check" alt="Check" /> {{ 'footerTitleItemProductNaharaSoftAcademic' | translate }}</a>
                        </li>-->
                    </ul>
                </div>
            </div>
            <div [ngClass]="['footerMain', 'col-12', 'col-sm-6', 'col-md-4', 'col-lg-3']">
                <h5 [ngClass]="['footerMainTitle']">{{ 'footerTitleServicesBusiness' | translate}}</h5>
                <div [ngClass]="['footerContent']">
                    <ul [ngClass]="['footerMainMenu']">
                        <li>
                            <a [routerLink]="['/software-development']" href="#"><img
                                    src="assets/images/icons/checked.svg" title="Check" alt="Check" /> {{ 'footerItemServicesSoftwareDevelopment' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="['/web-development']" href="#"><img src="assets/images/icons/checked.svg"
                                    title="Check" alt="Check" /> {{ 'footerItemServicesWebDevelopment' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="['/mobile-development']" href="#"><img
                                    src="assets/images/icons/checked.svg" title="Check" alt="Check" /> {{ 'footerItemServicesMobileDevelopment' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="['/web-design']" href="#"><img src="assets/images/icons/checked.svg"
                                    title="Check" alt="Check" /> {{ 'footerItemServicesWebDesign' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="['/consultancy-ti']" href="#"><img src="assets/images/icons/checked.svg"
                                    title="Check" alt="Check" /> {{ 'footerItemServicesConsultancyTI' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="['/ecommerce-project']" href="#"><img src="assets/images/icons/checked.svg"
                                    title="Check" alt="Check" /> {{ 'footerItemServicesProjectEcommerce' | translate}}</a>
                        </li>
                        <li>
                            <a [routerLink]="['/support']" href="#"><img src="assets/images/icons/checked.svg"
                                    title="Check" alt="Check" /> {{ 'footerItemServicesSupport' | translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div [ngClass]="[ 'footerMain', 'col-12', 'col-sm-6', 'col-md-4', 'col-lg-3'] ">
                <h5 [ngClass]="[ 'footerMainTitle'] ">{{ 'footerTitleFollowUsBusiness' | translate}}</h5>
                <div [ngClass]="[ 'footerContent'] ">
                    <a href="https://www.facebook.com/naharasoft/" target="_blank "><img
                            src="assets/images/social_networks/icon_facebook.webp "
                            [ngClass]="[ 'footerSocialNetworks'] "></a>
                    <a href="https://twitter.com/naharasoft/" target="_blank "><img
                            src="assets/images/social_networks/icon_twitter.webp "
                            [ngClass]="[ 'footerSocialNetworks'] "></a>
                    <a href="https://www.youtube.com/@naharasoft/" target="_blank "><img
                            src="assets/images/social_networks/icon_youtube.webp "
                            [ngClass]="[ 'footerSocialNetworks'] "></a>
                    <a href="https://www.instagram.com/naharasoft/" target="_blank "><img
                            src="assets/images/social_networks/icon_instagram.webp "
                            [ngClass]="[ 'footerSocialNetworks'] "></a>
                </div>
                <h5 [ngClass]="[ 'footerMainTitle'] ">{{ 'footerTitleAttentionScheduleBusiness' | translate}}</h5>
                <div [ngClass]="[ 'footerContent'] ">
                    <ul [ngClass]="[ 'footerMainMenu'] ">
                        <li><img src="assets/images/icons/clock.svg " title="{{ 'footerAltTitleWeekdayHours' | translate }} "
                                alt="{{ 'footerAltTitleWeekdayHours' | translate }} " /> {{ 'footerScheduleOne' | translate}}</li>
                           <!--<li><img src="assets/images/icons/clock.svg " title="{{ 'footerAltTitleWeekdayHours' | translate }} "
                                alt="{{ 'footerAltTitleWeekdayHours' | translate }} " /> {{ 'footerScheduleSecond' | translate}}</li>-->
                        <li><img src="assets/images/icons/clock.svg " title="{{ 'footerAltTitleSaturdayHours' | translate }}" alt="{{ 'footerAltTitleSaturdayHours' | translate }}" />
                            {{ 'footerScheduleThird' | translate}}</li>
                    </ul>
                </div>
                <h5 [ngClass]="[ 'footerMainTitle'] ">{{ 'footerTitleOurLanguagesBusiness' | translate}}</h5>
                <div [ngClass]="[ 'footerContent'] ">
                    <ul [ngClass]="[ 'footerMainMenu'] ">
                        <li>
                            <a href="# " [ngClass]="[ 'footerFlagCountry'] "><img
                                    src="assets/images/flags-mini/spanish.webp " title="{{ 'footerAltTitleLanguageEs' | translate }}"
                                    alt="{{ 'footerAltTitleLanguageEs' | translate }} " (click)="changeLang('es')"/></a>
                            <a href="# " [ngClass]="[ 'footerFlagCountry'] "><img
                                    src="assets/images/flags-mini/english.webp " title="{{ 'footerAltTitleLanguageEn' | translate }}"
                                    alt="{{ 'footerAltTitleLanguageEn' | translate }} " (click)="changeLang('en')"/></a>
                            <a href="# " [ngClass]="[ 'footerFlagCountry'] "><img
                                    src="assets/images/flags-mini/italian.webp " title="{{ 'footerAltTitleLanguageIt' | translate }}"
                                    alt="{{ 'footerAltTitleLanguageIt' | translate }} " (click)="changeLang('it')"/></a>
                        <li>
                    </ul>
                </div>
            </div>
        </div>
        <!--<div [ngClass]="['row', 'mainContentSubFooter']">
            <div [ngClass]="['footerMain', 'col-6', 'col-md-6']">
                <a [routerLink]="['/site-map']" href="#" [ngClass]="['link']">{{ 'footerSiteMap' | translate }}</a><br>
                <a [routerLink]="['/personal-data-processing-policies']" href="#" [ngClass]="['link']">{{ 'footerPersonalDataProcessingPolicies' | translate }}</a>
            </div>
            <div [ngClass]="['footerMain', 'col-3', 'col-md-2']">
                <img src="assets/images/images/marcaPais.webp" [ngClass]="['marcaPais']">
            </div>
        </div>-->
    </div>
</footer>
<div [ngClass]="[ 'main-footer-bar', 'container-fluid'] ">
    <div [ngClass]="[ 'container'] ">
        © 2023 {{ 'footerNameBussiness' | translate }} {{ 'footerAllRightsReserved' | translate}}
    </div>
</div>