import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Modules
import { WebRoutingModule } from './web-routing.module';
//Components
import { WebComponent } from './web.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ContentComponent } from './layout/content/content.component';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        ContentComponent,
        WebComponent
    ],
    imports: [
        CommonModule,
        WebRoutingModule    ,
        ParallaxScrollModule,    
        TranslateModule
    ],
    exports: [
        
    ],
    providers: [

    ]
})

export class WebModule {}